import React, {useState} from "react";
import * as styles from "src/styles/Mc.module.css";
import { SEO, Picture } from "src/components/commonComponents";
import Modal from 'react-modal';
import ROICalculator from "src/components/blocks/ROICalculator";
import meridianLogo from "src/images/new-version-homepage/Accruent-Meridian-Color.svg";
import meridianHero from "../../../static/staticPages/Migration_Meridian/Meridian_NewHero.jpg";
import demoImage from "../../../static/staticPages/Migration_Meridian/Meridian_Demo.jpg";
// const demoImage = "../../../static/staticPages/Migration_Meridian/Meridian_Demo.jpg";
import fileAlt from "../../../static/staticPages/Migration_Meridian/file-alt.svg";
import handShakeAlt from "../../../static/staticPages/Migration_Meridian/handshake-alt.svg";
import fileChart from "../../../static/staticPages/Migration_Meridian/file-chart-line.svg";
import listCheck from "../../../static/staticPages/Migration_Meridian/list-check-solid.svg";
import angleDoubleDown from "../../../static/staticPages/Migration_Meridian/angle-double-down.svg";
import infoSquare from "../../../static/staticPages/Migration_Meridian/info-square.svg";



import awardIcon from "../../../static/staticPages/Migration_Meridian/award.svg";
import objectIntersectIcon from "../../../static/staticPages/Migration_Meridian/object-intersect-solid.svg";


import mcLogoN from "../../../static/staticPages/mc-logo.png"
import service2 from "../../../static/staticPages/lucernex-logo.png"
import redeyeLogo from "../../../static/staticPages/redeye_logo.png"
import Dot from "../../../static/staticPages/Dot.svg"
import Play from "../../../static/staticPages/Play.svg"
import Play1 from "../../../static/staticPages/Play1.svg"
import arrowDownBlue from "../../../static/staticPages/arrowDownBlue.svg"
import arrowUpBlue from "../../../static/staticPages/arrowUpBlue.svg"
import arrowRightBlue from "../../../static/staticPages/arrowRightBlue.svg"
import benefit2 from "../../../static/staticPages/benefit2.svg"
import amtrol from "../../../static/staticPages/Migration_Meridian/Amtrol-Alfa-logo.png";


import zurich from "../../images/logos/accruent-partners/zurich-logo.png";
import mscm from "../../images/logos/accruent-partners/mscm-logo.png";
import abbvie from "../../images/logos/accruent-partners/abbvie-logo.png";
import huntsmanCorp from "../../images/logos/accruent-partners/huntsman-logo.png";
import totalEnergies from "../../../static/staticPages/Migration_Meridian/totalenergies.png";


import usersCog from "../../../static/staticPages/Migration_Meridian/users-cog.svg";
import user from "../../../static/staticPages/Migration_Meridian/users.svg";

import abbieVie from "../../../static/staticPages/Migration_Meridian/Abbvie.png";



import logo3 from "../../../static/staticPages/cook_white.png"
import logo4 from "../../../static/staticPages/logo4.svg"
import logo5 from "../../../static/staticPages/logo5.svg"
import logo6 from "../../../static/staticPages/logo6.svg"

import amtrolWhite from "../../../static/staticPages/Migration_Meridian/meridian-customers-white/Amtrol-Alfa-logo-white.png";
import totalWhite from "../../../static/staticPages/Migration_Meridian/meridian-customers-white/total-logo-white.png";
import zurichWhite from "../../../static/staticPages/Migration_Meridian/meridian-customers-white/zurich-logo-white.png";
import mcsm from "../../../static/staticPages/Migration_Meridian/meridian-customers-white/mscm-logo-white.png";
import abbvieWhite from "../../../static/staticPages/Migration_Meridian/meridian-customers-white/abbvie-logo-white.png";
import huntsmanWhite from "../../../static/staticPages/Migration_Meridian/meridian-customers-white/huntsman-logo-white.png";


import mastersWhite from "../../../static/staticPages/masters_gray.png"
import mcHeroBanner1 from "../../../static/staticPages/mcHeroBanner1.png"
import arrowRight from "../../../static/staticPages/arrowRight.svg"

import mscmResouce from "../../../static/staticPages/Migration_Meridian/MSCM-1A_optimized.jpg";
import amtrolResource from "../../../static/staticPages/Migration_Meridian/Meridian-Amtrol-Alfa-Case-Study.jpg";
import meridianResource from "../../../static/staticPages/Migration_Meridian/how-meridian-can-help.png";
import whitePaperResource from "../../../static/staticPages/Migration_Meridian/The-Unlikely-Trio.jpg";
import blogResource from "../../../static/staticPages/Migration_Meridian/Unlocking-Benefits-of-3D-in-Engineering-And-Design.jpg";


import design1 from "../../../static/staticPages/design1.png"
import mcLogo from "../../../static/staticPages/mc-logo.png"
import { useHeroImages } from "src/hooks/useHeroImages";
import { getPageDataJsonPath } from "src/utils";

import logoStrip1 from "../../../static/staticPages/emsLogoStrip1.svg"
import logoStrip2 from "../../../static/staticPages/emsLogoStrip2.png"
import logoStrip3 from "../../../static/staticPages/emsLogoStrip3.png"
import logoStrip4 from "../../../static/staticPages/emsLogoStrip4.svg"

import captera from "../../../static/staticPages/Migration_Meridian/captera-meridian.svg";
import softwareAdvise from "../../../static/staticPages/Migration_Meridian/software-advise.png";
import getApp from "../../../static/staticPages/Migration_Meridian/get-app.png";



import { useFormImages } from "src/hooks/useFormImages";
import { ContentMarketoForm } from "src/components/commonComponents/forms/ContentMarketoForm";
console.log("demoImage",demoImage)
const data = {
  ROICalculator: {
    "type": {
      "id": "roi_calculator"
    },
    "uuid": "78184b28-8a91-47bf-8b65-be5c530fc2c7",
    "nid": 14499,
    // "title": "ROI Calculator",
    "fieldHideTitle": false,
    "fieldShowTitleFullWidth": null,
    "fieldRoiCalculatorId": "dl_calculator",
    "fieldEmbedCode": "<script src=\"https://jamaica.value-cloud.com/embed.js\" width=\"100%\" height=\"100%\" calc=\"bff301a6ae9523504564434307fdf234\" currency_code=\"USD\" lang=\"en_US\" company=\"company_name\"></script>"
  },
  heroBanner:{
    title: 'Meridian: The Hybrid Engineering Document Management Solution',
    description:"Streamline engineering document management and support compliance with Meridian. Our EDMS helps organizations manage technical documents and drawings throughout their lifecycle, providing a centralized repository for storing, organizing, and controlling engineering documentation, ensuring the right people have the right information at the right time.",
    link1: {
      url: '/request-demo',
      text: 'Request a Demo'
    },
    link2: {
      url: '/demo',
      text: 'Interactive Demos'
    },
    image: {
      url: meridianHero,
      altText: 'An engineering professional uses EDMS software on mobile to check and manage documentation efficiently.'
    },
    logo: {
      url: meridianLogo,
      altText: "Accruent Meridian logo"
    }
  },
  logoStrip:[
    {
      url: captera,
      altText: 'Meridian Capterra rating 4.1 stars indicating high user satisfaction'
    },
    {
      url: getApp,
      altText: 'Meridian GetApp rating 4.1 stars indicating high user satisfaction'
    },
    {
      url: softwareAdvise,
      altText:'Meridian SoftwareAdvice rating 4.1 stars indicating high user satisfaction'
    }
  ],
  benefits: {
    title: "Transform Your Document Management",
    description: 'Gain control over your engineering drawings and documents, centralize access, boost collaboration, reduce costs and downtime, and ensure regulatory compliance.',
    items: [
      {
        logoUrl: fileAlt,
        altText: "File icon representing engineering content",
        title: "Control Engineering Content",
        description: "Fully control your engineering content with version management, secure printing, up-to-date documentation, controlled data changes, and configurable workflows.",
        // link:{
        //   text:'Explore regulatory compliance',
        //   url:'/'
        // }
      },
      {
        logoUrl: handShakeAlt,
        altText: "Handshake icon representing collaboration",
        title: "Boost Collaboration",
        description: "Find key asset information faster with a unified view of official documentation for engineers and operations teams, plus markup tools for seamless commenting and editing.",
        // link:{
        //   text:'Explore asset availability',
        //   url:'/'
        // }
      },
      {
        logoUrl: fileChart,
        altText: "File chart icon ",
        title: "Effortless Concurrent Engineering",
        description: "Streamline concurrent engineering with real-time visibility into document revisions across projects and operations, automatically notifying teams of updates to eliminate delays.",
        // link:{
        //   text:'Explore quality control',
        //   url:'/'
        // }
      },
      {
        logoUrl: listCheck,
        altText: "Checklist icon representing compliance",
        title: "Ensure Regulatory Compliance",
        description: "Ensure compliance with regulations and business processes through workflows, and comprehensive audit trails, protecting employees, facilities, product quality, and preventing costly fines.",
        // link:{
        //   text:'Explore inventory management',
        //   url:'/'
        // }
      },
      {
        logoUrl: angleDoubleDown,
        altText: "Down arrows icon representing no downtime.",
        title: "Reduce Costs and Downtime",
        description: "Avoiding asset failures and costly plant shutdowns with up-to-date information for work orders, managing contractor documentation and identifying data inconsistencies early for effective maintenance.",
        // link:{
        //   text:'Explore operation visibility',
        //   url:'/'
        // }
      },
      {
        logoUrl: infoSquare,
        altText: "Information icon",
        title: "Eliminate Information Silos",
        description: "Democratize data with a single source of truth for all engineering information through integrations with EAM, ERP, ECM, Office, and CAD applications.",
        // link:{
        //   text:'Explore operation visibility',
        //   url:'/'
        // }
      }
    ],
    info:{
      title: "Trusted by industry leaders across 75+ countries",
      images:[
      {
        url:amtrol,
        altText:"Amtrol logo"
      },
      {
        url: totalEnergies,
        altText: "TotalEnergies logo"
      },
      {
        url:zurich,
        altText:"Zurich Airport logo"
      },
      {
        url:mscm,
        altText:"MSCM Ltd logo"
      },
      {
        url:abbvie,
        altText:"AbbVie logo"
      },
      {
        url:huntsmanCorp,
        altText:"Huntsman Corporation logo"
      }
    ],
    link: {
      text:"Request a demo",
      url:'/request-demo'
    }
    }
  },
  threeColTwoRowComp: {
    title:"Engineering Document Management Made Easy",
    description: "Streamline management of technical documents and drawings throughout their lifecycle, boost collaboration, enhance safety, and reduce risks and down time with Meridian EDMS.",
    items:[
      {
        logoUrl: fileAlt,
        altText: "File icon representing engineering documents",
        title:"Control Engineering Documents",
        description: "Enhance document management, track parallel changes, and maintain projects under control. Reduce work order prep time with up-to-date information.",
      },
      {
        logoUrl: usersCog,
        altText: "Users and cog icon",
        title:"Optimize Operations Management",
        description: "Centralize critical documents, project files, and technical manuals to optimize facilities, operations, maintenance, and asset lifecycle management.",
      },
      { 
        logoUrl: user,
        altText: "User icon",
        title:"Protect Personnel Safety",
        description: "Ensure employee safety with instant access to current documentation, empowering teams to make informed decisions and prevent incidents.",
      },
      {
        logoUrl: handShakeAlt,
        altText: "Handshake icon representing collaboration",
        title:"Cultivate Cross Collaboration ",
        description: "Centralize information to support engineering, maintenance, IT, and contractors. Break silos, streamline collaboration, and ensure compliance with standardized document processes.",
      }
    ],
    // link:{
    //   text: 'Explore the many benefits Accruent software offers',
    //   url: '/request-demo'
    // }
  },
  fullWidthBanner:{
    image: {
      url: abbieVie,
      altText:"AbbVie Company logo"
    },
    title:"Project Manager, AbbVie",
    description: "Meridian manages our critical asset information for plant locations worldwide, allowing us to standardize and consolidate applications globally. This helps us to maintain consistent business processes and lowers the costs involved in having separate infrastructures around the world",
    name: "Brady Peterson",
    position: "Project Manager",
    // companyName: " Encore Wire Corporation"
  },
  logoSlider:{
    logoList:[
      {
        url: amtrolWhite,
        altText:'Amtrol logo'
      },
      {
        url: totalWhite,
        altText:"TotalEnergies logo"
      },
      {
        url: zurichWhite,
        altText:'Zurich Airport logo'
      },
      {
        url: mcsm,
        altText:'MSCM Ltd logo'
      },
      {
        url: abbvieWhite,
        altText:"AbbVie logo"
      },
      {
        url: huntsmanWhite,
        altText:"Huntsman Corporation logo"
      }
    ]
  },
  features: {
    title: 'Maximize Engineering Efficiency and Compliance with Meridian',
    description: "Optimize your engineering operations with centralized documents, compliance assurance, and seamless system integration.",
    logo:{
      url: meridianLogo,
      altText: 'Accruent Meridian Logo'
    },
    items: [
      {
        logoUrl: awardIcon,
        altText: "Award icon",
        title: "Your Single Source of Truth",
        description: "Centralize engineering documents in a single source of truth for accurate, up-to-date information that is accessible internally and externally.",
        // link:{
        //   text:'Explore Manufacturing',
        //   url:'/industries/manufacturing'
        // }
      },
      {
        logoUrl: listCheck,
        altText: "Checklist icon representing compliance",
        title: "Ensure Compliance ",
        description: "Respond effectively to emergencies and automate efforts to maintain compliance with regulations like FDA CFR Part 11, ISO 55000, and GMP.",
        // link:{
        //   text:'Explore Oil & Gas',
        //   url:'/industries/utilities/oil-gas'
        // }
      },
      {
        logoUrl: objectIntersectIcon,
        altText: "Integrate icon",
        title: "Seamlessly Integrate",
        description: "Unlock your engineering data’s full value by connecting Meridian with your existing systems such as EAM, ERP, ECM, Microsoft Office, and more.",
        // link:{
        //   text:'Explore Pharmaceutical',
        //   url:'/industries/manufacturing/process-manufacturing/pharmaceutical'
        // }
      }
    ]
  },
  heroBanner1:{
    title: 'Look under the hood',
    description:'Learn more about our software, get your questions answered, and find out how it can complement your current technology and processes',
    link1: {
      url: '/request-demo',
      text: 'Request a demo'
    },
    link2: {
      url: '/contactus',
      text: 'Contact Sales'
    },
    image: {
      url: mcHeroBanner1,
      alt: "Three customers asking questions and learning more about Accruent's software."
    }
  },
  LinkStrip: {
    url:'/',
    text:'Calculate what impact Maintenance Connection will have on your business',
    link1:{
      url:'/',
      text:'Calculate Meridian Pricing'
    },
    link2:{
      url:'/',
      text:'Calculate what impact Meridian will have on your business'
    }
  },
  resources: {
    title: "Some resources you might find interesting",
    link:{
      url:'/resources',
      text:"Explore Accruent resources"
    },
    resourceList:{
      resource1: {
        image:{
          url:mscmResouce,
          altText: 'Image of a manufacturing plant utilizing EDMS software for efficient document management.'
        },
        title:"How MSCM Ltd consolidated more than 260,000 documents into an enterprise-wide, digital system ",
        description:"Read how MSCM Ltd used Meridian to consolidate its more than 260,000 documents into an enterprise-wide, digital system and significantly increased efficiency.",
        link: 'https://www.accruent.com/resources/case-studies/mscm',
        button:{
          text: 'Case Study',
        }
      },
      resource2: {
        image:{
          url:amtrolResource,
          altText: 'A manufacturing professional uses EDMS for effective document control and management.'
        },
        title:"How Amtrol-Alfa Maintains ISO 9001 Compliance and Streamlines Document Control",
        description:"Amtrol- Alfa maintains ISO 9001 compliance and streamlines document control with Meridian",
        link: 'https://www.accruent.com/resources/case-studies/amtrol-alfa-iso-compliance-meridian',
        button:{
          text: 'Case Study',
        }
      },
      list:[
        {
          image:{
            url:meridianResource,
            altText: 'A picture of EDMS software in action on a laptop screen, showcasing its document management features.'
          },
          title:"How Meridian EDMS Can Help Every Department in Your Organization",
          link: 'https://www.accruent.com/resources/brochures/meridian-edms-helps-every-department-organization',
          button:{
            text: 'Brochure',
          }
        },
        {
          image:{
            url:whitePaperResource,
            altText: 'An engineering professional uses mobile EDMS on a tablet to manage documentation efficiently on the go.'
          },
          title:"The Unlikely Trio: The Convergence of Digitalization, Reliability, and Sustainability in the Industrial Workplace",
          link: 'https://www.accruent.com/resources/white-papers/digitalization-reliability-sustainability-industrial-workplace',
          button:{
            text: 'White Paper',
          }
        },
        {
          image:{
            url:blogResource,
            altText: 'Engineer examing documents using CMMS and EDMS integration'
          },
          title:"Unlocking the benefits of 3D in engineering and design",
          link: 'https://www.accruent.com/resources/blog-posts/unlocking-benefits-of-3d-in-engineering-and-design',
          button:{
            text: 'Blog',
          }
        }
      ]
    },
    walkthrough: {
      title:"Walkthrough demonstrations",
      items:[
        {
          logo:{
            url: Play1,
            altText: 'Alt text'
          },
          title:"Streamline Electronic Signatures ",
          description: "Discover Meridian’s electronic signature feature, offering automated requests, customization, and streamlined compliance",
          link: 'https://accruent.wistia.com/medias/wjo55l5j5m',
          wistiaId: 'wjo55l5j5m'
        },
        {
          logo:{
            url: Play1,
            altText: 'Alt text'
          },
          title:"Enhanced Safety with Meridian",
          description: "Ensure safety with centralized document management, providing quick access to accurate, up-to-date information.",
          link: 'https://accruent.wistia.com/medias/e7tpxr5cye',
          wistiaId: 'e7tpxr5cye'
        },
        {
          logo:{
            url: Play1,
            altText: 'Alt text'
          },
          title:"AbbVie’s Project Efficiency with Meridian",
          description: "See how AbbVie gained a one-stop shop for efficient facility maintenance, ensuring up-to-date drawings and streamlined approvals.",
          link: 'https://accruent.wistia.com/medias/5dpx90afua',
          wistiaId: '5dpx90afua'
        },
        {
          logo:{
            url: Play1,
            altText: 'Alt text'
          },
          title:"Ensuring Compliance with Meridian",
          description: "Discover how Meridian ensures regulatory compliance and document quality through automated checks, streamlining project documentation and improving standards.",
          link: 'https://accruent.wistia.com/medias/bq3hlcsl6s',
          wistiaId: 'bq3hlcsl6s'
        }
      ]
    }
  },
  support:{
    logo:{
      url: meridianLogo,
      altText: 'Accruent Meridian Logo'
    },
    title: 'Frequently Asked Questions',
    list: [
      {
        title: 'What is document control in engineering?',
        descList: [
          {
            text: '<span>Document control in engineering refers to the organized management of documents throughout the lifecycle of an organization. This includes stages like creation, approval, distribution, and storage of documents for easy reference.</span>'
          },
          {
            text: '<span>Document types might include project plans, technical manuals, design mockups, or quality assurance documents.</span>'
          },
          {
            text: '<span>By maintaining control over these important documents, organizations can guarantee regulatory compliance for all team members.</span>'
          },
        ]
      },
      {
        title: 'How does Meridian’s EDMS streamline document management?',
        descList: [
          {
            text: '<span>When multiple contributors work on a project, it can be hard to track changes effectively. Meridian’s EDMS allows for parallel changes, providing an extensive overview of all changes made to any document.</span>'
          },
          {
            text: '<span>Any stakeholder can have access to this data and visualize changes in real time, minimizing the risk of miscommunications amongst team members.</span>'
          },
          {
            text: '<span>Meridian strives for collaboration, transparency, and efficiency throughout each project.</span>'
          }
        ]
      },
      {
        title: 'Does this EDMS facilitate company-wide collaboration?',
        descList: [
          {
            text: '<span>Yes, this engineering document management system keeps everyone in the loop. Teams can develop predefined processes for document creation and review, as well as work from a structured framework for tasks.</span>'
          },
          {
            text: '<span>Meridian also connects with existing tools so that organizations do not have to abandon any existing systems.</span>'
          },
          {
            text: '<span>Its user-friendly interface makes this EDMS accessible to anyone within the organization. There is no steep learning curve to interpreting and utilizing the platform.</span>'
          },
        ]
      },
      {
        title: 'Does Meridian’s EDMS support compliance standards?',
        descList: [
          {
            text: '<span>Accountability is everything in an EDMS, and Meridian takes this seriously. From audit trails to access level adjustments, your organization can ensure that all processes are up to legal standards.</span>'
          },
          {
            text: '<span>For documents that require formal approval, Meridian’s EDMS allows for electronic signatures so that compliance standards are clearly observed.</span>'
          },
          {
            text: '<span>Transitioning to a paperless workflow might seem daunting at first, but Meridian’s EDMS puts your organization’s security needs front of mind.</span>'
          }
        ]
      },
      {
        title: 'What is the difference between EDMS and ECM?',
        descList: [
          {
            text: '<span>EDMS refers to engineering specific organizations and associated documents including drawings, mockups, environmental assessments, and technical data. An ECM encompasses a broader range of content including emails, images, and other records, but it is not specific to the engineering field.</span>'
          },
          {
            text: '<span>Both are intended to organize key documents to ensure that team members can more easily collaborate, and organizations can meet their compliance standards.</span>'
          }
        ]
      }

    ],
    contact: {
      title: 'Don’t see your question? Let us help you',
      link: {
        url:'/contactus',
        text:'Contact support'
      }
    }
  },
  design:{
    title:"Products designed to integrate your world",
    description: "Accruent software allows you to fully optimize the lifecycle of your asset and facilities, offering connected workflows, integrated experiences and data-backed insights.",
    link: {
      url:'/products',
      text:'Explore all Accruent products'
    },
    productList:[
      {
        url: design1,
        altText: 'Accruent EMS Logo',
        link:'/products/ems'
      },
      {
        url:redeyeLogo,
        altText: 'Accruent Redeye Logo',
        link:'/products/redeye'
      },
      {
        url: mcLogo,
        altText: 'Accruent Maintenance Connection Logo',
        link:'/products/maintenance-connection'
      },
      {
        url: service2,
        altText: 'Accruent Lucernex Logo',
        link:'/products/lucernex'
      }
    ]
  }
}

const HeroBanner = (heroImages) => {
  return <>
     <div className={styles.heroContainer}>
        <div className={styles.leftWrapper}>
          <div className={styles.heroLogoTitleWrapper}>
            <img src={data?.heroBanner?.logo?.url} alt={data?.heroBanner?.logo?.altText} className={styles.heroLogo}></img>
            <h1 className={styles.heroTitle}>
              {data?.heroBanner?.title}
            </h1>
          </div>
          <p className={styles.heroDesc}>{data?.heroBanner?.description}</p>
          <div className={styles.heroBtnWrapper}>
            <a href={'#mktoForm'} className={styles.heroBtn1Wrapper}>
              <span className={styles.heroBtn1Text}>{data?.heroBanner?.link1?.text}</span>
              <div className={styles.heroBtn1}>
                <img className={styles.heroBtn1Img} src= {Dot}></img>
              </div>
            </a>
            <a href={data?.heroBanner?.link2?.url} className={styles.heroBtn2Wrapper}>
              <span className={styles.heroBtn2Logo}><img src= {Play}></img></span>
              <span className={styles.heroBtn2Text}>{data?.heroBanner?.link2?.text}</span>
            </a>
        </div>
        </div>
        <div>
          {/* <img className={styles.heroImage} src={data?.heroBanner?.image?.url} alt={data?.heroBanner?.image?.altText}></img> */}
          <Picture
            className={styles.heroImage}
            image={heroImages.heroImages['staticPages/Migration_Meridian/Meridian_NewHero-1.jpg']}
            rel="preload"
            alt={data?.heroBanner?.image?.altText}
          />
        </div>
     </div>
  </>
}

const Benefits = () => {
  return (
    <div className={styles.benefitContainer}>
      <div className={styles.benefitGridCompWrapper}>
        <div className={styles.benefitHeadingWrapper}>
          <h2 className={styles.benefitTitle}>{data?.benefits?.title}</h2>
          <p className={styles.benefitDesc}>{data?.benefits?.description}</p>
        </div>
        <div className={styles.featureGridWrapper}>
          {
            data?.benefits?.items?.map((item,index)=>
              <div key={index} className={styles.featureGridCard}>
              <div className={styles.featureBox}>
                <div className={styles.featureWrapper}>
                  <div className={styles.featureLogoWrapper}>
                    <img src={item?.logoUrl} alt={item?.altText} className="tw-min-h-[25px] "></img>
                  </div>
                  <h3 className={styles.featureGridTitle}>
                    {item?.title}
                  </h3>
                  <p className={styles.featureGridDesc}>{item?.description}</p>
                </div>
              </div>
              {item?.link ? (
                  <a href={item?.link?.url} className={styles.featureCardLinkWrapper}>
                    <span className={styles.featureCardLinkText}>{item?.link?.text}</span>
                    <img src={arrowRightBlue} alt='Right arrow'></img>
                  </a>
              ) : ""}
              </div>
            )
          }
        </div>      
      </div>
      <div className={styles.benefitProductsWrapper}>
        <p className={styles.benefitProductsText}>{data?.benefits?.info?.title}</p>
        <div className={styles.benefitProductsLogoList}>
          {
            data?.benefits?.info?.images?.map((image,index)=>
              <div key={index} className={styles.benefitProductsLogo}>
                <img src={image?.url} alt={image?.altText} className="tw-max-h-[90px] tw-max-w-[200px] "></img>
              </div>
            )
          }
        </div>
        <a href={'#mktoForm'} className={styles.heroBtn1Wrapper}>
              <span className={styles.heroBtn1Text}>{data?.benefits?.info?.link?.text}</span>
              <div className={styles.heroBtn1}>
                <img className={styles.heroBtn1Img} src= {Dot}></img>
              </div>
            </a>
      </div>
    </div>
  )
}

const ThreeColComp = () => {
  return (
    <div className={styles.threeColContainer}>
      <div className={styles.threeColHeadWrapper}>
        <h2 className={styles.threeColTitle}>{data?.threeColTwoRowComp?.title}</h2>
        <p className={styles.threeColDesc+ " tw-max-w-[1237px]"}>{data?.threeColTwoRowComp?.description}</p>
      </div>
      <div className={styles.threeColGridWrapper}>
        {
          data?.threeColTwoRowComp?.items?.map((item, index)=>
            <div key={index} className={styles.threeColGridBox}>
              <div className={styles.threeColGrid}>
                <div className={styles.threeColLogoWrapper}>
                  <img className="tw-min-h-[25px] tw-min-w-[25px]" src={item?.logoUrl} alt={item?.altText}></img>
                </div>
                <h3 className={styles.threeColGridTitle}>{item?.title}</h3>
                <p className={styles.threeColGridDesc}>{item?.description}</p>
              </div>
            </div>
          )
        }
      </div>
      {data?.threeColTwoRowComp?.link ? (
        <a href={data?.threeColTwoRowComp?.link?.url} className={styles.threColLinkWrapper}>
          <span className={styles.threeColLinkText}>{data?.threeColTwoRowComp?.link?.text}</span>
          <img src={arrowRightBlue} alt='Right arrow'></img>
        </a>
      ) : ""}
    </div>
  )
}

const FullWidthBanner = () => {
  return (
    <div className={styles.fullWidthBannerContainer}>
      <div className={styles.fullWidthBannerWrapper}>
        <div className={styles.fullWidthBannerLogoWrapper}>
          <div className={styles.fullWidthBannerLogo}>
            <img className={styles.fullWidthBannerImage} src={data?.fullWidthBanner?.image?.url} 
              alt={data?.fullWidthBanner?.image?.altText} loading="lazy"></img>
          </div>
        </div>
        <div className={styles.fullWidthBannerTextWrapper}>
          <p className={styles.fullWidthBannerTitle}>
            {data?.fullWidthBanner?.title}
          </p>
          <p className={styles.fullWidthBannerDesc}>{data?.fullWidthBanner?.description}</p>
          <div>
            <p className={styles.fullWidthBannerName}>— {data?.fullWidthBanner?.name}</p>
            <p className={styles.fullWidthBannerDesc}>{data?.fullWidthBanner?.position}</p>
          </div>
        </div>
      </div>
    </div>
  )
}

const LogoSlider = () => {
  return (
    <div className={styles.logoSliderContainer}>
      <div className={styles.logoStrip}>
      { data?.logoSlider?.logoList?.map((logo, index)=>
        <img key={index} className={styles.logoSliderImage + " tw-max-w-[100px] tw-object-contain"} src={logo?.url} alt={logo?.altText}></img>
      )
      }
      </div>
    </div>
  )
}

const Features = () => {
  return (
    <div className={styles.featureContainer}>
      <div className={styles.featureGridCompWrapper}>
        <div className={styles.featureHeadingWrapper}>
          <img src={data?.features?.logo?.url} alt={data?.features?.logo?.altText} className={styles.heroLogo}></img>
          <h2 className={styles.featureTitle}>{data?.features?.title}</h2>
          <p className={styles.featureDesc}>{data?.features?.description}</p>
        </div>
        <div className={styles.featureGridWrapper}>
          {
            data?.features?.items?.map((item,index)=>
              <div key={index} className={styles.featureGridCard}>
              <div className={styles.featureBox}>
                <div className={styles.featureWrapper}>
                  <div className={styles.featureLogoWrapper}>
                    <img src={item?.logoUrl} alt={item?.altText} className="tw-min-h-[25px] "></img>
                  </div>
                  <h3 className={styles.featureGridTitle}>
                    {item?.title}
                  </h3>
                  <p className={styles.featureGridDesc}>{item?.description}</p>
                </div>
              </div>
              {item?.link?.url && <a href={item?.link?.url} className={styles.featureCardLinkWrapper}>
                <span className={styles.featureCardLinkText}>{item?.link?.text}</span>
                <img src={arrowRightBlue} alt='Right arrow'></img>
              </a>}
              </div>
            )
          }
        </div>       
      </div>
    </div>
  )
}

const LogoStrip = () => {
  return (
    <div className={styles.LogoStripContainer}>
      {
        data?.logoStrip?.map((img, index)=>(
          <img key={index} src={img?.url} altText={img?.altText}></img>
        ))
      }
    </div>
  )
}

const HeroBanner1 = () => {
  return <>
     <div className={styles.heroContainer1}>
        <div className={styles.hero1leftWrapper}>
          <h2 className={styles.hero1Title}>
            {data?.heroBanner1?.title}
          </h2>
          <p className={styles.hero1Desc}>{data?.heroBanner1?.description}</p>
          <div className={styles.hero1BtnWrapper}>
            <a href={'#mktoForm'} className={styles.hero1Btn1Wrapper}>
              <span className={styles.hero1Btn1Text}>{data?.heroBanner1?.link1?.text}</span>
              <div className={styles.hero1Btn1}>
                <img className={styles.hero1Btn1Img} src= {Dot}></img>
              </div>
            </a>
            <a href={data?.heroBanner1?.link2?.url} className={styles.hero1Btn2Wrapper}>
              <span className={styles.hero1Btn2Text}>{data?.heroBanner1?.link2?.text}</span>
              <img src= {arrowRight}></img>
            </a>
        </div>
        </div>
        <div>
          <img className={styles.hero1Image} src={data?.heroBanner1?.image?.url} alt={data?.heroBanner1?.image?.alt} loading="lazy"></img>
        </div>
     </div>
  </>
}

const customStylesPricing = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: '0',
    border: 'none',
    borderRadius: '0px',
    overflow: 'auto'
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.75)'
  }
};

const closeButtonStylesPricing = {
  position: 'absolute',
  top: '10px',
  right: '10px',
  background: 'rgba(0, 0, 0, 0.5)',
  border: 'none',
  fontSize: '20px',
  cursor: 'pointer',
  color: '#fff',
  zIndex: '1000',
  padding: '5px',
};

const PricingModal = ({ isOpen, onRequestClose }) => (
  <Modal isOpen={isOpen} onRequestClose={onRequestClose} style={customStylesPricing} className={''}>
    <div style={{height:'560px', width:'1024px'}}>
      <button onClick={onRequestClose} style={closeButtonStylesPricing}>x</button>
      <div>
        <iframe className="pricing-iframe" src="https://app.calconic.com/api/embed/calculator/64892da028c7470029d3e74e" sandbox="allow-same-origin allow-forms allow-scripts allow-top-navigation allow-popups-to-escape-sandbox allow-popups" title="Calconic_ Calculator" name="Calconic_ Calculator" height="640px" width="1024px" scrolling="yes" ></iframe>
      </div>
    </div>
  </Modal>
);

const LinkStrip = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalIsOpenROI, setModalIsOpenROI] = useState(false);

  const openModalPricing = () => {
    setModalIsOpen(true);
  };

  const closeModalPricing = () => {
    setModalIsOpen(false);
  };
  const openModalROI = () => {
    setModalIsOpenROI(true);
  };

  const closeModalROI = () => {
    setModalIsOpenROI(false);
  };
  return (
    <>
      <div className={styles.LinkStripContainer} style={{  gridTemplateColumns: '1fr 1fr'}}>
        {/* <a href={data?.LinkStrip?.url} className={styles.LinkStripWrapper} onClick={() => openModal()}> */}
          {/* <span className={styles.LinkStripText}>{data?.LinkStrip?.text}</span> */}
          {/* <img src={arrowRight} alt='Right arrow'></img> */}
          <div className={styles.linkStripLeftWrapper} onClick={() => openModalPricing()} style={{background: 'linear-gradient(0deg,#313030,#313030),#fff'}}>
            <div className={styles.LinkStripWrapper}>
              <span className={styles.LinkStripText + " !tw-text-[17px]"}>{data?.LinkStrip?.link1?.text}</span>
              <img src={arrowRight} alt='Right arrow'></img>
            </div>
            {/* <a href={data?.LinkStrip?.link1?.url} className={styles.LinkStripWrapper}> */}
            {/* </a> */}
          </div>
          <div className={styles.linkStripRightWrapper} onClick={() => openModalROI()}>
            <div className={styles.LinkStripWrapper}>
              <span className={styles.LinkStripText + "  !tw-text-[17px]"}>{data?.LinkStrip?.link2?.text}</span>
              <img src={arrowRight} alt='Right arrow'></img>
            </div>
          </div>
        {/* </a> */}
      </div>
      <PricingModal
        isOpen={modalIsOpen}
        onRequestClose={closeModalPricing}
      />
      <ROICalculatorModal
        isOpen={modalIsOpenROI}
        onRequestClose={closeModalROI}
      />
    </>
  )
}

const Resource = () => {
  return (
    <div className={styles.resourceContainer}>
      <div className={`${styles.resourceHeadingWrapper} container`}>
        <h2 className={styles.resourceTitle}>{data?.resources?.title}</h2>
        <a href={data?.resources?.link?.url} className={styles.resourceLinkWrapper}>
          <span className={styles.resourceLinkText}>{data?.resources?.link?.text}</span>
          <img src={arrowRightBlue} alt='arrow button'></img>
        </a>
      </div>
      <div className={styles.resourceWrapper}>
        <div className={`${styles.resourceCardWrapper} container`}>
          <a href={data?.resources?.resourceList?.resource1?.link} className={styles.resourceCard}>
            <img className={styles.resourceCardImg} src={data?.resources?.resourceList?.resource1?.image?.url} alt={data?.resources?.resourceList?.resource1?.image?.altText}/>
            <div className={styles.resourceCardTextWrapper}>
              <p className={styles.resourceCardTitle}>{data?.resources?.resourceList?.resource1?.title}</p>
              <p className={styles.serviceCardDesc}>{data?.resources?.resourceList?.resource1?.description}</p>
              <div className={styles.resourceCardBtnWrapper + " tw-static lg:tw-absolute tw-bottom-[30px]"}>
                <div className={styles.resourceCardBtn}>{data?.resources?.resourceList?.resource1?.button?.text}</div>
              </div>
            </div>
          </a>
          <a href={data?.resources?.resourceList?.resource2?.link} className={styles.resourceCard}>
            <img className={styles.resourceCardImg} src={data?.resources?.resourceList?.resource2?.image?.url} alt={data?.resources?.resourceList?.resource2?.image?.altText}/>
            <div className={styles.resourceCardTextWrapper}>
              <p className={styles.resourceCardTitle}>{data?.resources?.resourceList?.resource2?.title}</p>
              <p className={styles.serviceCardDesc}>{data?.resources?.resourceList?.resource2?.description}</p>
              <div className={styles.resourceCardBtnWrapper + " tw-static lg:tw-absolute tw-bottom-[30px]"}>
                <div className={styles.resourceCardBtn}>{data?.resources?.resourceList?.resource2?.button?.text}</div>
              </div>
            </div>
          </a>
          <div className={styles.resourceMultiCardWrapper}>
            {
              data?.resources?.resourceList?.list?.map((item, index)=>
              <a key={index} href={item?.link} className={styles.resourceMultiCard}>
              <div>
                <img className={styles.resourceMultiCardImg} src={item?.image?.url} alt={item?.image?.altText}></img>
                <div className={styles.resourceCardBtnWrapper + " md:!tw-hidden !tw-flex tw-mt-[15px] tw-w-fit"}>
                    <div className={styles.resourceCardBtn}>{item?.button?.text}</div>
                </div>
              </div>
              <div className={styles.resourceMultiCardTextWrapper}>
                <p className={styles.resourceMultiCardText}>{item?.title}</p>
                <div className={styles.resourceCardBtnWrapper + " md:!tw-flex !tw-hidden"}>
                  <div className={styles.resourceCardBtn}>{item?.button?.text}</div>
                </div>
              </div>
            </a>
              )
            }
          </div>
        </div>
      </div>
      <div className={styles.walkthroughWrapper}>
        <h2 className={styles.walkthroughTitle}>{data?.resources?.walkthrough?.title}</h2>
        <div className={styles.walkthroughFlexWrapper}>
          <VideoGrid />
        </div>
      </div>
    </div>
  )
}

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: '0',
    border: 'none',
    borderRadius: '0px',
    overflow: 'hidden'
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.75)'
  }
};

const closeButtonStyles = {
  position: 'absolute',
  top: '10px',
  right: '10px',
  background: 'rgba(0, 0, 0, 0.5)',
  border: 'none',
  fontSize: '20px',
  cursor: 'pointer',
  color: '#fff',
  zIndex: '1000',
  padding: '5px',
};

const VideoModal = ({ isOpen, onRequestClose, wistiaId }) => (
  <Modal isOpen={isOpen} onRequestClose={onRequestClose} style={customStyles}>
    <div>
      <button onClick={onRequestClose} style={closeButtonStyles}>x</button>
      <div
        className={`wistia_embed wistia_async_${wistiaId} videoFoam=true`}
        style={{height:'360px', width:'640px'}}
      >
        &nbsp;
      </div>
    </div>
  </Modal>
);

const VideoGrid = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [currentVideo, setCurrentVideo] = useState(null);

  const openModal = (wistiaId) => {
    setCurrentVideo(wistiaId);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setCurrentVideo(null);
  };

  return (
    <>
      {data?.resources?.walkthrough?.items?.map((video, index) =>
        <div className={styles.walkthroughCardWrapper} onClick={() => openModal(video.wistiaId)}>
          <img src={video?.logo?.url} alt={video?.logo?.altText}></img>
          <div className={styles.walkthroughCardText}>
            <p className={styles.walkthroughCardTitle}>{video?.title}</p>
            <p className={styles.walkthroughCardDesc}>{video?.description}</p>
          </div>
        </div>
      )
      }
      <VideoModal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        wistiaId={currentVideo}
      />
    </>
  );
};

const Question = ({item}) => {
  const [open, setOpen] = useState(false)
  return (
    <div className={styles.supportListWrapper}>
      <div onClick={()=>setOpen(!open)} className={styles.supportListTitleWrapper}>
        <img src={open ? arrowUpBlue : arrowDownBlue} alt='arrow'></img>
        <h3 className={styles.supportListTitle}>{item?.title}</h3>
      </div>
      {open && <div className={styles.supportListDescWrapper}>
          {
            item?.descList?.map((i, index)=>
              <p key={index} className={styles.supportListDesc} dangerouslySetInnerHTML={{ __html: i?.text }}></p>
            )
          }
        </div>
      }
    </div>
  )
}

const Support = () => {
  return (
    <div className={styles.supportContainer}>
      <div className={styles.supportHeadWrapper}>
        <img className={styles.supportLogo} src={data?.support?.logo?.url} alt={data?.support?.logo?.altText}></img>
        <h2 className={styles.supportTitle}>{data?.support?.title}</h2>
        <div className={styles.supportQListWrapper}>
        {
          data?.support?.list?.map((item, index)=>
            <Question key={index} item={item}/>
          )
        }
      </div>
      </div>
      <div className={styles.supportContactWrapper}>
        <p className={styles.supportContactText}>{data?.support?.contact?.title}</p>
        <a className={styles.supportContactLink} href={data?.support?.contact?.link?.url}>
          <span className={styles.supportContactLinkText}>{data?.support?.contact?.link?.text}</span>
          <img src={arrowRightBlue} alt='arrow right'></img>
        </a>
      </div>
    </div>
  )
}

const Design = () => {
  return (
    <div className={styles.designContainer}>
      <div className={styles.designTextWrapper}>
        <div className={styles.designTitleWrapper}>
          <h2 className={styles.designTitle}>{data?.design?.title}</h2>
          <div className={styles.designDescWraper}>
            <p className={styles.designDesc}>{data?.design?.description}</p>
            <a href={data?.design?.link?.url} className={styles.designLink}>
              <span className={styles.designLinkText}>{data?.design?.link?.text}</span>
              <img src={arrowRightBlue} alt='arrow img'></img>
            </a>
          </div>
        </div>

      </div>
      <div className={styles.designLogoContainer}>
        <div className={styles.designLogoStrip}>
          {
            data?.design?.productList?.map((item, index)=>
              <a href={item?.link}  key={index} className={styles.designLogoWrapper}>
                <img className={styles.designLogo} src={item?.url} alt={item?.altText}></img>
              </a>
            )
          }
        </div>
      </div>
    </div>
  )
}

const customStylesROI = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: '0',
    border: 'none',
    borderRadius: '0px',
    overflow: 'auto'
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.75)'
  }
};

const closeButtonStylesROI = {
  position: 'absolute',
  top: '10px',
  right: '10px',
  background: 'rgba(0, 0, 0, 0.5)',
  border: 'none',
  fontSize: '20px',
  cursor: 'pointer',
  color: '#fff',
  zIndex: '1000',
  padding: '5px',
};

const ROICalculatorModal = ({ isOpen, onRequestClose }) => (
  <Modal isOpen={isOpen} onRequestClose={onRequestClose} style={customStylesROI} className={''}>
    <div style={{height:'560px', width:'1024px'}}> 
      <button onClick={onRequestClose} style={closeButtonStylesROI}>x</button>
      {/* <div> */}
        <ROICalculator
              data={data.ROICalculator}
        />
      {/* </div> */}
    </div>
  </Modal>
);

export default function Mc() {
  const heroImages = useHeroImages();
  const formImages = useFormImages()
  const pageDataPath = getPageDataJsonPath('/products/meridian')
  const contentEntity = {
    "uuid": "abfd837b-6954-4cb8-a9c1-9c100cccda3b",
    "nid": 4046,
    "fieldEmbedCode": "<script src=\"//app-abc.marketo.com/js/forms2/js/forms2.min.js\"></script>\r\n<form id=\"mktoForm_1693\"></form>\r\n<script>MktoForms2.loadForm(\"//app-abc.marketo.com\", \"167-BOY-362\", 1693, function(form) {\r\n    dataLayer.push({\r\n      'event': 'gaTriggerEvent',\r\n      'gaCategory': 'CTA Engagement',\r\n      'gaAction': 'Form Load',\r\n      'gaLabel': 'Demo Request',\r\n    });\r\n    form.onSuccess(function(values, followUpUrl) {\r\n      dataLayer.push({\r\n        'event': 'gaTriggerEvent',\r\n        'gaCategory': 'CTA Engagement',\r\n        'gaAction': 'Form Submission',\r\n        'gaLabel': 'Demo Request',\r\n      });\r\n    });\r\n  });\r\n</script>",
    "title": "Request A Demo ",
    "fieldFormHeader": "Request A Demo",
    "fieldHideTitle": false,
    "fieldShowTitleFullWidth": null,
    "fieldFormText": null,
    "fieldFormImage": {
        "alt": "An engineer on site using a laptop to operate EDM software"
    },
    "relationships": {
        "fieldFormImage": {
        },
        "fieldFormImageWithCover": formImages['staticPages/form-face.png'],
        "fieldMediaImage": {
            "fieldImage": {
                "alt": "bg"
            },
            "relationships": {
                "field_image": formImages['staticPages/form-bg.jpg'],
            }
        }
    },
    "type": {
        "id": "marketo_forms"
    },
    "fieldVersions": "advanced",
    "fieldCtaUrl": null
  }
  return (
    <>
      <SEO
        meta={
          [
            { 'name': 'title', 'content': "Meridian Engineering Document Management System | Accruent" },
            { 'name': 'description', 'content': "Streamline engineering document management with Meridian's EDMS. Ensure safety, compliance, and enhance collaboration for a centralized asset information hub." }
          ]
        }
        heroImage={heroImages['staticPages/Migration_Meridian/Meridian_NewHero-1.jpg']}
        preloads= {[pageDataPath]}
        lang="en"
        pageUrl="/products/meridian"
        alternatives={[
          {
              "href": "/products/meridian",
              "hreflang": "en"
          },
          {
              "href": "/uk/products/meridian",
              "hreflang": "en-gb"
          },
          {
              "href": "/products/meridian",
              "hreflang": "x-default"
          }
      ]}
      />
      <HeroBanner heroImages={heroImages}/>
      <LogoStrip />
      <Benefits/>
      <ThreeColComp/>
      <FullWidthBanner/>
      <LogoSlider/>
      <Features/>
      <HeroBanner1/>
      <LinkStrip/>
      <Resource/>
      <Support/>
      <div id="mktoForm">
        <ContentMarketoForm
          entity={contentEntity}
          locale="en"
          pageTitle="Meridian: Engineering Document Management Made Easy"
          formFullWidth={false}
          demoImage={demoImage}
        />
      </div>
      <Design/>
    </>
  );
}